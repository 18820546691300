<template>
    <div>
        <zw-media v-model="inputVal"
                  type="article"
                  accepted-files="image/*"
                  :parent_id="ids"
                  preview
                  :cols="12"
        ></zw-media>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    props: {
        'value': {},
        'labelPrefix': {},
        'ids': {},
    },
    methods: {
    },
    mounted() {
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    }
}
</script>